.order-schedule-date-time-container {
    display: flex;
}

.order-schedule-date-container{
    flex: 1;
}

.order-schedule-time-container{
    flex: 1;
}