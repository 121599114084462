.placeholder-item {
    // box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
    position: relative;
    overflow: hidden;
    background-color: #e0e0e0;
}

.placeholder-item::before {
    content: "";
    display: block;
    position: absolute;
    left: -300px;
    top: -300px;
    height: 1000px;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #eaeaea 50%, transparent 100%);
    animation: load 1s cubic-bezier(1, 1, 1, 1) infinite;
    transform: rotate(45deg);
}

@keyframes load {
    from {
        left: -300px;
    }
    to {
        left: 200%;
    }
}
