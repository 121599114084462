.checkbox-container {
    display: flex;
    align-items: center;

    .checkbox {
        min-height: 28px;
        min-width: 28px;
        height: 28px;
        width: 28px;
        border: 1px solid #c8c8c8;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.disabled {
            border: 1px solid #dbdbdb;
        }

        .tick {
            height: 80%;
            width: 80%;
            background-color: transparent;
            border-radius: 3px;

            &.checked {
                background-color: var(--primary-color);
            }
        }
    }

    .checkbox-children {
        line-height: 1.5em;
    }
}
