.input {
    font-size: 14px;
    border: 1px solid #e0e0e0;
    width: 100%;
    padding: 8px 12px;
    line-height: 24px;
    border-radius: 4px;
    background-color: #f7f7f7;

    &.error {
        border: 1px solid var(--error-color);
        background-color: hsl(var(--error-hue), var(--error-saturation), 98%);
    }

    &.disabled {
        background-color: #eeeeee;
    }
}

.input::placeholder {
    color: hsl(0, 0%, 20%);
}
