.link {
    color: var(--lnk-color);
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
}

.link:hover {
    color: hsl(var(--lnk-hue), var(--lnk-saturation), 15%);
}
