.product-modal {
    display: flex;
    flex-direction: column;
    height: 100%;

    .product-image {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
    }

    .product {
        flex: 1;
        padding: 0 40px 40px 40px;
        // overflow: scroll;
        // -ms-overflow-style: none; /* IE and Edge */
        // scrollbar-width: none; /* Firefox */

        @media (max-width: 744px) {
            padding: 24px;
        }

        .description {
            white-space: pre-line;
            font-weight: 300;
            font-size: 16px;
            line-height: 1.5em;

            @media (max-width: 744px) {
                font-size: 14px;
            }
        }

        .modifier-sub-modifier-group-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            .modifier-sub-modifier-group {
                padding: 8px 24px;
                border: 1px solid #e0e0e0;
                border-radius: 30px;
                cursor: pointer;

                &.background-grey {
                    background-color: #e0e0e0;
                }

                &.selected {
                    background-color: var(--primary-color);
                    color: var(--secondary-text-color);
                    border: none;
                }
            }
        }

        .modifier-group-header-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;

            .modifier-group-header {
                flex: 1;
            }
        }

        .modifiers {
            display: grid;
            grid-gap: 12px;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

            .modifier {
                &.sold-out {
                    opacity: 0.5;
                }

                .collapsed-stepper-container {
                    display: flex;
                    align-items: center;

                    .collapsed-stepper {
                        cursor: pointer;
                        border: 1px solid #c8c8c8;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.disabled {
                            border: 1px solid #dcdcdc;
                            color: #dcdcdc;
                        }
                    }

                    .collapsed-stepper-children {
                        line-height: 1.5;
                    }
                }

                .modifier-item-wrapper {
                    width: 100%;
                    cursor: pointer;

                    .modifier-item {
                        display: flex;
                        align-items: center;
                        font-size: 18px;

                        .description {
                            font-size: 16px;
                        }

                        .image {
                            height: 120px;
                            border-radius: 8px;
                            object-fit: cover;
                        }

                        .quantity-remaining {
                            padding: 5px 10px;
                            background-color: var(--primary-color);
                            color: var(--secondary-text-color);
                            border-radius: 5px;
                            font-size: 12px;
                        }

                        .product-modifier-chevron-right {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .product::-webkit-scrollbar {
        display: none;
    }

    .footer {
        padding: 24px;
        border-top: 1px solid rgb(224, 224, 224);

        .stepper {
            width: 140px;
            margin-left: auto;
            margin-right: auto;
        }

        .footer-buttons-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .add-update-order-button {
            width: 350px;
        }

        .cancel-button {
            background-color: var(--background-color);
            color: #484848;
            border: 1px solid #e0e0e0;
            width: 350px;
        }
    }
}
