.restaurant {
    padding: 18px 50px;
    // height: 100vh;
    display: flex;

    @media (max-width: 744px) {
        padding: 0;
        margin-bottom: 80px;
    }

    .restaurant-container {
        // display: flex;
        flex: 1;
        overflow: scroll;

        .restaurant-logo-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .restaurant-logo {
                width: 100px;
                padding-top: 24px;
            }
        }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    // .categories-wrapper::-webkit-scrollbar {
    //     display: none;
    //     overflow: hidden;
    // }

    /* Hide scrollbar for IE, Edge and Firefox */
    .categories-wrapper {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        padding: 24px;
        display: flex; /* displays flex-items (children) inline */
        overflow-x: auto;

        @media (min-width: 744px) {
            padding: 48px 32px 32px 32px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            overflow: scroll;
            // border-right: 1px solid #e0e0e0;
        }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .products-wrapper::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .products-wrapper {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        overflow: scroll;
        padding: 48px 32px 32px 32px;
        width: 100%;

        @media (max-width: 744px) {
            padding: 16px;
        }

        .product-category-name {
            margin-bottom: 48px;

            @media (max-width: 744px) {
                margin-bottom: 24px;
            }
        }

        .product-sub-category-wrapper {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            justify-content: center;

            @media (max-width: 744px) {
                overflow-x: overlay;
                justify-content: left;
                flex-wrap: nowrap;
            }

            .product-sub-category {
                padding: 8px 12px;
                border: 1px solid #e0e0e0;
                border-radius: 30px;
                cursor: pointer;
                flex-shrink: 0;

                &.background-grey {
                    background-color: #e0e0e0;
                }

                &.selected {
                    background-color: var(--primary-color);
                    color: var(--secondary-text-color);
                    border: none;
                }
            }
        }

        .products {
            display: grid;
            grid-gap: 12px;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

            .product {
                position: relative;
                border: 1px solid #e0e0e0;
                padding: 12px;
                border-radius: 10px;
                opacity: 1;

                &.sold-out {
                    opacity: 0.5;
                }

                .quantity-remaining {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    padding: 5px 10px;
                    background-color: var(--primary-color);
                    color: #ffffff;
                    border-radius: 5px;
                    font-size: 12px;
                }

                .image {
                    width: 100%;
                    height: 200px;
                    border-radius: 10px;
                    object-fit: cover;
                }

                .name {
                    font-size: 18px;
                    line-height: 1.5em;
                    text-align: center;
                }

                .description {
                    min-height: 3em;
                    line-height: 1.5em;
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-weight: 300;
                    text-align: center;
                }

                .tags {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;

                    .tag {
                        font-size: 12px;
                        margin: 2px;
                        padding: 6px 8px;
                        border: 1px solid var(--primary-color);
                        border-radius: 500px;
                        color: var(--primary-color);
                    }
                }

                .display-price {
                    text-align: center;
                    font-size: 18px;
                }

                .price {
                    text-align: center;
                    font-size: 18px;
                }
            }

            .add-to-cart {
                border: 1px solid black;
                position: relative;

                .product-quantity {
                    position: absolute;
                    top: -15px;
                    right: -15px;
                    background-color: var(--primary-color);
                    color: white;
                    border-radius: 50%;
                    height: 35px;
                    width: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        @media (max-width: 744px) {
            .products {
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

                .product {
                    .image {
                        height: 150px;
                    }

                    .name {
                        font-size: 16px;
                    }

                    .price {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .category {
        border-radius: 10px;
        margin: 6px;
        padding: 16px;
        line-height: 16px;
        border: 1px solid #e0e0e0;
        display: flex;
        align-items: center;
        flex-shrink: 0;

        &.background-grey {
            background-color: #e0e0e0;
        }

        &.selected {
            background-color: var(--primary-color);
            color: white;
            // border-bottom: 8px solid var(--primary-color);
        }

        .name {
            .available {
                opacity: 1;
            }

            .unavailable {
                opacity: 0.5;
            }
        }

        .icon {
            height: 24px;
            margin-right: 10px;
        }
    }

    .footer-wrapper {
        border-top: 1px solid #e0e0e0;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        padding: 16px 24px;
        height: 80px;
        background-color: white;

        .total-container {
            display: flex;
            align-items: center;

            .total-wrapper {
                display: flex;
                align-items: center;
                flex: 1;
                font-weight: 400;

                // .shopping-bag-icon {
                //     height: 52px;
                // }

                .total {
                    font-weight: 400;
                }
            }
        }

        .view-my-order-button {
            float: right;
        }
    }

    .checkout-container {
        padding: 28px 32px;
        width: 300px;
        height: 100%;
        border: 1px solid #e0e0e0;
        position: sticky;
        top: 0;
    }
}
