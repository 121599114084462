.select-container {
    border: 1px solid #ebebeb;
    position: relative;
    border-radius: 4px;

    .select {
        font-size: 14px;
        line-height: 24px;
        padding: 8px 12px;
        width: 100%;
        border-radius: 4px;
        background-color: #f7f7f7;

        &.disabled {
            background-color: #ebebeb;
            color: #9d9d9d;
        }

        &.error {
            border: 1px solid var(--error-color);
            background-color: hsl(var(--error-hue), var(--error-saturation), 98%);
        }
    }

    .arrow {
        position: absolute;
        top: 14px;
        right: 12px;
        pointer-events: none; /* pass through */
    }
}
