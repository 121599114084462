.up-sell-cross-sell {
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
        flex: 1;
        padding: 68px;
        overflow: scroll;

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        .main-image {
            width: 100%;
            border-radius: 16px;
        }

        .button-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .no-thank-you-button {
                background-color: #ffffff;
                color: #484848;
                border: 1px solid #e0e0e0;
                width: 250px;
                font-size: 22px;
            }

            .add-to-order-button {
                width: 250px;
                font-size: 22px;
            }
        }

        .products {
            width: 100%;
            display: grid;
            grid-gap: calc(20px / 2);
            grid-auto-flow: column;
            grid-auto-columns: calc(50% - 20px * 2);
            overflow-x: scroll;

            .product {
                position: relative;
                border: 1px solid #e0e0e0;
                padding: 16px;
                border-radius: 10px;
                opacity: 1;

                &.sold-out {
                    opacity: 0.5;
                }

                .quantity-remaining {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    padding: 5px 10px;
                    background-color: var(--primary-color);
                    color: #ffffff;
                    border-radius: 5px;
                    font-size: 12px;
                }

                .image {
                    width: 100%;
                    height: 200px;
                    border-radius: 10px;
                    object-fit: cover;
                }

                .name {
                    font-size: 18px;
                    text-align: center;
                }

                .description {
                    min-height: 3em;
                    line-height: 1.5em;
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-weight: 300;
                    text-align: center;
                }

                .tags {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;

                    .tag {
                        font-size: 12px;
                        margin: 2px;
                        padding: 6px 8px;
                        border: 1px solid var(--primary-color);
                        border-radius: 500px;
                        color: var(--primary-color);
                    }
                }

                .price {
                    text-align: center;
                    font-size: 18px;
                }
            }
        }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .content::-webkit-scrollbar {
        display: none;
    }
}
