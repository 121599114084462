.stepper-container {
    display: flex;
    align-items: center;

    .stepper {
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr auto;
        grid-column-gap: 8px;

        .stepper-button {
            border: 1px solid #c8c8c8;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.disabled {
                border: 1px solid #dcdcdc;
                color: #dcdcdc;
            }
        }
    }

    .stepper-children {
        line-height: 1.5em;
    }
}
