// @font-face {
//     font-family: BigFish;
//     src: url("https://tabin-public.s3.ap-southeast-2.amazonaws.com/restaurant-fonts/Big+Fish/Big+Fish.otf") format("opentype");
// }

// @font-face {
//     font-family: RonniaReg;
//     src: url("https://tabin-public.s3.ap-southeast-2.amazonaws.com/restaurant-fonts/Ronnia/RonniaReg.otf") format("opentype");
// }

// @font-face {
//     font-family: RonniaBold;
//     src: url("https://tabin-public.s3.ap-southeast-2.amazonaws.com/restaurant-fonts/Ronnia/RonniaBold.otf") format("opentype");
// }

:root {
    --primary-color: #2b318c;
    --secondary-color: #2b318c;
    --background-color: #ffffff;
    --font-color: #484848;
}

body {
    // font-family: "RonniaReg", Fallback, sans-serif;
    background-color: var(--background-color);
    color: var(--font-color);
}

.restaurant-list {
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 600px;
    width: 100%;
    margin: auto;

    .restaurant-list-logo {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 32px;
    }

    .restaurant-list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}
