.textArea {
    border: 1px solid #e0e0e0;
    width: 100%;
    font-size: 14px;
    padding: 8px 12px;
    line-height: 24px;
    border-radius: 4px;
    resize: none;
    background-color: #f7f7f7;

    .error {
        border: 1px solid var(--error-color);
        background-color: hsl(var(--error-hue), var(--error-saturation), 98%);
    }

    .disabled {
        background-color: #eeeeee;
    }
}

/* https://medium.com/@willhowardgb/building-a-beautiful-text-input-component-in-react-f85564cc7e86 */
/* comparable to normal hsl(0, 0%, 60%) */
.textArea::-webkit-input-placeholder {
    color: hsl(0, 0%, 30%);
}
/* comparable to normal hsl(0, 0%, 60%) */
.textArea::-moz-placeholder {
    color: hsl(0, 0%, 30%);
}
/* comparable to normal hsl(0, 0%, 60%) */
.textArea:-ms-input-placeholder {
    color: hsl(0, 0%, 30%);
}
/* comparable to normal hsl(0, 0%, 60%) */
.textArea:-moz-placeholder {
    color: hsl(0, 0%, 30%);
}
